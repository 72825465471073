.home .empty[data-v-8c5a7f6a] {
  width: 100%;
  height: 700px;
  text-align: center;
  background-color: white;
  padding-top: 100px;
  margin-bottom: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.home .empty img[data-v-8c5a7f6a] {
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
}
.home .empty p[data-v-8c5a7f6a] {
    text-align: center;
    color: #2779c4;
    font-size: x-large;
    font-weight: 900;
    font-family: KaiTi;
}
.home footer[data-v-8c5a7f6a] {
  background-color: #409eff;
  height: 130px;
  text-align: center;
  color: white;
  /*   position: absolute;
    bottom: 0;
    left: 0; */
  width: 100%;
}
.home footer p[data-v-8c5a7f6a] {
    text-align: center;
    font-size: xx-small;
}
.home footer .tips1[data-v-8c5a7f6a] {
    line-height: 50px;
}
.home footer .tips2[data-v-8c5a7f6a] {
    line-height: 30px;
}
